import { defineNuxtMiddleware } from '@nuxtjs/composition-api';
import { usePreferences } from '@wellcare/nuxt-module-account/compositions';
const exchangeTokenFn = async ({ ctx, set, clear, accessToken }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    try {
        const baseUrl = new URL(location.href);
        const cookies = (ctx === null || ctx === void 0 ? void 0 : ctx.$cookies) || ((_a = ctx.app) === null || _a === void 0 ? void 0 : _a.$cookies);
        const axios = (ctx === null || ctx === void 0 ? void 0 : ctx.$axios) || ((_b = ctx === null || ctx === void 0 ? void 0 : ctx.app) === null || _b === void 0 ? void 0 : _b.$axios);
        const resExchangeToken = await axios.post('/identity/public/flexa-aia/exchange-token', {
            exchangeToken: accessToken
        });
        const code = (_c = resExchangeToken.data) === null || _c === void 0 ? void 0 : _c.code;
        const exchangeToken = (_e = (_d = resExchangeToken.data) === null || _d === void 0 ? void 0 : _d.results) === null || _e === void 0 ? void 0 : _e.exchangeToken;
        if (code === 200) {
            // Login
            await set({ key: 'exchangeToken', value: exchangeToken });
            const resLogin = await ctx.$axios.post('/identity/login', {
                exchangeToken
            });
            if (((_f = resLogin.data) === null || _f === void 0 ? void 0 : _f.code) === 200) {
                const user = (_g = resLogin.data) === null || _g === void 0 ? void 0 : _g.results;
                const { accessToken, refreshToken } = user;
                // Update the authentication state in the store
                ctx.store.commit('updateField', {
                    path: 'authen',
                    value: {
                        user,
                        token: accessToken
                    }
                });
                ctx.$axios.setHeader('token', accessToken);
                cookies.set('token', accessToken);
                // Set the token and user data in preferences
                await set({ key: 'token', value: accessToken });
                await set({ key: 'refreshToken', value: refreshToken });
                await set({
                    key: 'user',
                    value: JSON.stringify({
                        _id: user === null || user === void 0 ? void 0 : user._id,
                        phone: user === null || user === void 0 ? void 0 : user.phone,
                        countryCode: user === null || user === void 0 ? void 0 : user.countryCode,
                        avatar: user === null || user === void 0 ? void 0 : user.avatar
                    })
                });
                ctx.redirect(baseUrl.pathname);
            }
            else if (((_h = resLogin.data) === null || _h === void 0 ? void 0 : _h.code) === 401) {
                ctx.redirect({
                    name: 'active'
                });
            }
            else {
                await clear();
                ctx.error({ statusCode: 401, message: 'Unauthorized' });
            }
        }
        else {
            await clear();
            ctx.error({ statusCode: 401, message: 'Unauthorized' });
        }
    }
    catch (e) {
        const res = e === null || e === void 0 ? void 0 : e.response;
        const status = res === null || res === void 0 ? void 0 : res.status;
        await clear();
        if (status === 401) {
            ctx.error({ statusCode: 401, message: 'Unauthorized' });
        }
        else {
            ctx.error({ statusCode: 500, message: 'Unexpected error' });
        }
    }
};
export default defineNuxtMiddleware(async (ctx) => {
    var _a;
    const { set, get, clear } = usePreferences();
    const exchangeTokenQuery = (_a = ctx.query) === null || _a === void 0 ? void 0 : _a.access_token;
    if (exchangeTokenQuery && typeof exchangeTokenQuery === 'string') {
        await exchangeTokenFn({ ctx, set, clear, accessToken: exchangeTokenQuery });
    }
    else {
        const tokenLocal = await get({ key: 'token' });
        if (!tokenLocal.value) {
            await clear();
            ctx.error({ statusCode: 401, message: 'Unauthorized' });
        }
    }
});
