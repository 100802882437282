const getPhone = function (app, consultationId, userId) {
    const query = {
        user: userId
    };
    return new Promise((resolve, reject) => {
        app
            .$apiCall({
            url: `/api/cc/get-phone-consultation/${consultationId}`,
            query,
            method: 'get'
        }, null)
            .then((res) => {
            if (res.code === 200) {
                resolve(res.results);
            }
            else {
                reject(res.message);
            }
        })
            .catch((err) => {
            reject(err);
        });
    });
};
export default getPhone;
