const rejectedReason = function (app, type = 'indepth', role = 'user') {
    return new Promise((resolve, reject) => {
        const query = {
            for: role
        };
        app
            .$apiCall({
            url: `/api/consultation/${type}/rejection-template`,
            query,
            method: 'get'
        })
            .then((res) => {
            if (res.code === 200) {
                resolve(res.results);
            }
            else {
                reject(res);
            }
        })
            .catch((err) => {
            reject(err);
        });
    });
};
export default rejectedReason;
/**
 *
 * scheme: https
    host: apis.[staging.]wellcare.vn
    endpoint: /api/consultation/:type=indepth|question/rejection-template
    method: GET
    headers: {
        token, signature
    }
    resposne: {
    }
 */
