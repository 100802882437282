import { computed, useStore } from '@nuxtjs/composition-api';
export const useAppBar = () => {
    const { commit, state } = useStore();
    const getTitle = computed(() => { var _a; return ((_a = state.appBar) === null || _a === void 0 ? void 0 : _a.title) || ''; });
    const getUrlBack = computed(() => { var _a; return (_a = state.appBar) === null || _a === void 0 ? void 0 : _a.urlBack; });
    const setTitle = (val) => {
        commit('appBar/SET_TITLE', val);
    };
    const setUrlBack = (val) => {
        commit('appBar/SET_URL_BACK', val);
        console.log('setted');
    };
    return {
        getTitle,
        getUrlBack,
        setTitle,
        setUrlBack
    };
};
