import { provider, calendar, user, consultation, vaccines, file } from '@wellcare/web-client/services/apis';
import * as newApiGateway from '@wellcare/web-client/services/newApiGateway';
import { consultation as consultationManually, prescription as prescriptionManually, provider as providerManually, diagnosis as diagnosisManually } from '../services/apis/index';
const pluginServices = ({ app }, inject) => {
    inject('apiClient', {
        getCalendarByUserId: (userId) => provider.getCalendarId(app, userId),
        getCalendarItems: (data) => provider.getCalendarItems(app, data),
        searchProviderTimeslot: (_id, data) => provider.searchProviderTimeslot(app, _id, data),
        setConsultationRescheduled: (_consultationId, data) => consultationManually.reschedule(app, _consultationId, data),
        // * Synchronize && Backup Google Calendar
        getListCalendarGoogle: (userId) => calendar.google.backup(app, userId),
        synchronizeCalendarGoogle: (calendarId, data) => calendar.google.sync(app, calendarId, data),
        mergeUserByPassOpt: (data) => user.mergeUser(app, data),
        getConsultationsHistory: (data, query) => consultation.historySearch(app, data, query),
        login: (data) => user.login(app, data),
        getVaccines: () => vaccines.getVaccinesList(app),
        // ? Manipulated Diagnosis
        createDiagnosis: (data) => consultation.diagnosis.create(app, data),
        getDiagnosisById: (data) => consultation.diagnosis.getDiagnosisById(app, data),
        removeDiagnosisById: (data) => consultation.diagnosis.remove(app, data),
        // searchDiagnosis: (data: any) => consultation.diagnosis.search(app, data),
        searchDiagnosis: (data) => diagnosisManually.search(app, data),
        // ? Consultation
        getConsultationDetail: (consutationId) => consultation.getConsultationDetail(app, consutationId),
        getConsultationById: (_id) => consultationManually.getConsultationById(app, _id),
        getPrescriptionAutoFill: (query) => prescriptionManually.getPrescriptionAutoFill(app, query),
        savePrescriptionAutoFill: (data) => prescriptionManually.savePrescriptionAutoFill(app, data),
        getConsultationPhone: (consultationId, userId) => consultationManually.getConsultationPhone(app, consultationId, userId),
        switchPhoneNumber: (consultationId, userId, data) => consultationManually.switchPhoneNumber(app, consultationId, userId, data),
        requestCall: (data) => consultationManually.requestCall(app, data),
        ratingProvider: (consultationId, data) => consultationManually.rating(app, consultationId, data),
        completeConsultation: (consultationId, data) => consultationManually.complete(app, consultationId, data),
        rejectConsultation: (consultationId, action, data) => consultationManually.reject(app, consultationId, action, data),
        rejectedReason: (type, role = 'user') => consultationManually.rejectedReason(app, type, role),
        // ? File
        fileRemove: (uniqueName, urc) => file.remove(app, uniqueName, urc),
        answerText: (consultationId, data) => consultationManually.answerText(app, consultationId, data),
        // ? Provider
        getConsultationDashboard: (userId) => providerManually.getConsultationDashboard(app, userId)
    });
    inject('apiGateway', {
        login: (data) => newApiGateway.login(app, data),
        register: (data) => newApiGateway.register(app, data),
        registerService: (data, token) => newApiGateway.registerService(app, data, token),
        getChartBySlug: (source, slug, token) => newApiGateway.getChartBySlug(app, source, slug, token),
        getObservationsList: (key, query, token) => newApiGateway.observation.list(app, key, query, token),
        getObservationsListLikeKey: (key, query, token) => newApiGateway.observation.listLikeKey(app, key, query, token),
        getPreviewHealthRecord: (key, userId, token) => newApiGateway.observation.previewMedicalHealth(app, key, userId, token),
        createObservation: (data, token) => newApiGateway.observation.create(app, data, token),
        createObservationGroup: (data, token) => newApiGateway.observation.createGroup(app, data, token),
        createRelationship: (data, token) => newApiGateway.consultation.createRelationship(app, data, token),
        editObservation: (id, data, token) => newApiGateway.observation.edit(app, id, data, token),
        editObservationGroup: (data, token) => newApiGateway.observation.editGroup(app, data, token),
        feedback: (data, token) => newApiGateway.feedback(app, data, token)
    });
};
export default pluginServices;
