const search = function (app, data) {
    return new Promise((resolve, reject) => {
        app
            .$apiCall({
            url: `/api/diagnosis/v3/search?locale=${data.locale}&search=${data.search}&custom=${JSON.stringify(data.custom)}&skip=0&limit=100`,
            method: 'get'
        })
            .then((res) => {
            if (res.code === 200) {
                resolve(res.results);
            }
            else {
                reject(res);
            }
        })
            .catch((err) => {
            reject(err);
        });
    });
};
export default search;
