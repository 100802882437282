const complete = function (app, consultationId, data) {
    return new Promise((resolve, reject) => {
        app
            .$apiCall({
            url: `/api/consultation/${consultationId}/complete`,
            method: 'post',
            data
        })
            .then((res) => {
            if (res.code === 200) {
                resolve(res.results);
            }
            else {
                reject(res);
            }
        })
            .catch((err) => {
            reject(err);
        });
    });
};
export default complete;
/**
 *
 * scheme: https
    host: apis.[staging.]wellcare.vn
    endpoint: /api/consultation/:consultationId/complete
    method: POST
    headers: {
        token, signature
    }
    body: null || {
        state: "FREE"
    }
    resposne: {
        "Consultation": {
            "state": "FREE"
        }

 */
