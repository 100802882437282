import { computed, defineComponent, ref, useContext, useRoute, watch } from '@nuxtjs/composition-api';
import { usePreferences } from '@wellcare/vue-component';
import { useAppBar } from '../composables';
export default defineComponent({
    middleware: [
        'authenApp',
        async ({ redirect }) => {
            const { get } = usePreferences();
            const exchangeToken = await get({ key: 'exchangeToken' });
            if (!exchangeToken.value) {
                redirect('/');
            }
        },
        'locale',
        'track'
    ],
    setup() {
        const { i18n, $config } = useContext();
        const route = useRoute();
        const { setTitle } = useAppBar();
        const dialog = ref(false);
        const matchedPath = computed(() => route.value.matched);
        const debug = computed(() => $config.debug);
        const items = computed(() => [
            {
                text: i18n.t('Home'),
                href: '/'
            },
            ...matchedPath.value.map((path) => ({
                text: path.name,
                to: path.components.default
            }))
        ]);
        const closeNavigate = () => {
            try {
                ;
                window.onCloseWebview();
            }
            catch (err) {
                console.log('70 ', err);
            }
        };
        const getUrl = () => {
            return document.URL;
        };
        watch(() => route.value.path, (val) => {
            const title = val.split('/')[2] || '';
            setTitle(title);
        });
        return {
            route,
            closeNavigate,
            items,
            matchedPath,
            dialog,
            getUrl,
            debug
        };
    }
});
