import { useRoute, useRouter, computed, useContext, defineComponent, ref } from '@nuxtjs/composition-api';
export default defineComponent({
    middleware: ['exchange-token', 'authenApp', 'locale', 'track'],
    setup() {
        const { i18n, $config } = useContext();
        const route = useRoute();
        const debug = computed(() => $config.debug);
        const matchedPath = computed(() => route.value.matched);
        const router = useRouter();
        const items = computed(() => [
            {
                text: i18n.t('Home'),
                href: '/'
            },
            ...matchedPath.value.map((path) => ({
                text: path.name,
                to: path.components.default
            }))
        ]);
        const closeNavigate = () => {
            try {
                ;
                window.onCloseWebview();
            }
            catch (err) {
                console.log('70 ', err);
            }
        };
        const backNavigate = () => {
            if (route.value.path === '/') {
                closeNavigate();
            }
            else
                router.push('/');
        };
        const dialog = ref(false);
        const getUrl = () => {
            return document.URL;
        };
        return {
            route,
            backNavigate,
            closeNavigate,
            items,
            matchedPath,
            dialog,
            getUrl,
            debug
        };
    }
});
