const savePrescriptionAutoFill = function (app, data) {
    return new Promise((resolve, reject) => {
        app
            .$apiCall({
            url: `/api/prescription/auto-fill`,
            method: 'post',
            data
        })
            .then((res) => {
            if (res.code === 200) {
                console.log('Service 200', res);
                resolve(res.results);
            }
            else {
                reject(res);
            }
        })
            .catch((err) => {
            reject(err);
        });
    });
};
export default savePrescriptionAutoFill;
