import { getField, updateField } from 'vuex-composition-map-fields';
const state = () => ({
    title: '',
    urlBack: {
        path: ''
    }
});
const getters = {
    getField
};
const mutations = {
    updateField,
    SET_TITLE(state, val) {
        state.title = val;
    },
    SET_URL_BACK(state, val) {
        state.urlBack = val;
    }
};
export default {
    namespaced: true,
    strict: true,
    state,
    getters,
    mutations
};
