import { defineComponent, useRoute } from '@nuxtjs/composition-api';
export default defineComponent({
    middleware: ['track'],
    props: {
        error: {
            type: Object,
            default: () => { }
        }
    },
    setup() {
        const route = useRoute();
        return {
            route
        };
    }
});
