import { computed, ref, useContext, useRoute, useRouter } from '@nuxtjs/composition-api';
import { defineComponent } from '@vue/composition-api';
export default defineComponent({
    middleware: ['locale', 'track'],
    setup() {
        const { $config } = useContext();
        const debug = computed(() => $config.debug);
        const router = useRouter();
        const route = useRoute();
        const dialog = ref(false);
        const getUrl = () => {
            return document.URL;
        };
        const closeNavigate = () => {
            try {
                ;
                window.onCloseWebview();
            }
            catch (err) {
                console.log('57 ', err);
            }
        };
        const backNavigate = () => {
            if (route.value.path === '/') {
                closeNavigate();
            }
            else
                router.push('/');
        };
        return { route, dialog, getUrl, backNavigate, debug };
    }
});
