import { defineComponent, computed, useRouter } from '@nuxtjs/composition-api';
import { useAppBar } from '../composables';
export default defineComponent({
    name: 'AppBar',
    setup() {
        const router = useRouter();
        const { getTitle, getUrlBack } = useAppBar();
        const appBarTitle = computed(() => getTitle.value || '');
        const backNavigate = () => {
            var _a;
            if ((_a = getUrlBack.value) === null || _a === void 0 ? void 0 : _a.path) {
                router.push(getUrlBack.value);
            }
            else {
                router.back();
            }
        };
        return {
            appBarTitle,
            backNavigate
        };
    }
});
