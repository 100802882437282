export default function (app, id, data) {
    return new Promise((resolve, reject) => {
        app
            .$apiCall({
            url: `/api/consultation/${id}/answer`,
            method: 'post',
            data
        })
            .then((res) => {
            if (res.code === 200) {
                resolve(res.results);
            }
            else {
                reject(res);
            }
        })
            .catch((err) => {
            reject(err);
        });
    });
}
