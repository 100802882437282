import { render, staticRenderFns } from "./auth.vue?vue&type=template&id=070664f0&scoped=true"
import script from "./auth.vue?vue&type=script&lang=ts"
export * from "./auth.vue?vue&type=script&lang=ts"
import style0 from "./auth.vue?vue&type=style&index=0&id=070664f0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "070664f0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppBar: require('/usr/src/app/src/components/AppBar.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';
installComponents(component, {VApp,VBtn,VCard,VCardText,VCardTitle,VContainer,VDialog,VIcon,VMain})
