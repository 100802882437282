export default (app, consultationId, data) => app.$apiCall({
    url: `/api/consultation/${consultationId}/provider/reschedule`,
    method: 'put',
    data
});
/**
 *  API PUT: {HOST}/consultation/:consultationId/provider/reschedule
    body: {
      isSurcharge
      startTime
      slotIndex
      endTime
    }
*/
