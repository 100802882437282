const reject = function (app, consultationId, action, data) {
    return new Promise((resolve, reject) => {
        app
            .$apiCall({
            url: `/api/consultation/${consultationId}/${action}`,
            method: 'post',
            data
        })
            .then((res) => {
            if (res.code === 200) {
                resolve(res.results);
            }
            else {
                reject(res);
            }
        })
            .catch((err) => {
            reject(err);
        });
    });
};
export default reject;
/**
 * scheme: https
    host: apis.[staging.]wellcare.vn
    endpoint: /api/consultation/:id/:action=cancel|reject
    method: POST
    headers: {
        token, signature
    }
    body: {
        "by": { "role" : "...", "user": userId},
        "content": {"reason": "...", "comment": "..."}
    }
    resposne: {
        "Consultation": {
            "state": "REJECTED" | "CANCELLED"
        }
    }
 */
