const switchPhoneNumber = function (app, consultationId, userId, data) {
    const query = {
        user: userId
    };
    return new Promise((resolve, reject) => {
        app
            .$apiCall({
            url: `/api/cc/switch-phone-consultation/${consultationId}`,
            method: 'post',
            data,
            query
        })
            .then((res) => {
            if (res.code === 200) {
                resolve(res.results);
            }
            else {
                reject(res.message);
            }
        })
            .catch((err) => {
            reject(err);
        });
    });
};
export default switchPhoneNumber;
